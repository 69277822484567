// ** Site header
.site-header {
  background: #faf9f5;
  border-top: 5px solid $title-color;
  border-bottom: 1px solid $grey-color-light;
  min-height: 5px;
  text-align: left;

  // Positioning context for the mobile navigation icon
  position: relative;
  overflow: auto;

  .links {
    list-style: none;
    float: right;
    margin-bottom: 0;
    line-height: 56px;
    font-size: 15px;
    font-family: 'Noto Naskh Arabic';
    min-width: 250px;
    text-align: right;

    li {
      display: inline;
    }

    li:before {
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      content: "|";
      color: #88002F;
    }

    li:first-child:before {
      content: "";
      margin-right: 0;
      margin-left: 0;
    }

    a {
      color: #88002F;
    }

    a:hover {
      color: #610102;
      border-bottom: 1px dotted #780102;
    }

    // language switch button
    li.lang_link:before {
      content: "";
      margin-right: 0;
      margin-left: 0;
    }

    li.lang_link {
      border: 0.055rem gray solid;
      // border-radius: 4rem !important;
      background-color: #f3f3f1;
      margin-left: 0.5rem;

      padding-left: 0.6rem;
      padding-right: 0.6rem;
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
      font-weight: bolder;

      a {
        color: #787065;
        text-decoration: all;
      }
      &:hover {
        border-color: rgb(94, 0, 0);
        background-color: #efefef;
        a {
          color: rgb(94, 0, 0);
          border-bottom: 0px;
        }
      }
      a:hover {
        color: rgb(94, 0, 0);
        border-bottom: 0px;
      }
    }
  }

  @include media-query($on-palm) {
    .links {
      font-size: 0.7rem;
      margin-top: 0.4rem;
      padding-top: 0.3rem;
      float: none;
      text-align: right;
      li {
        text-align: right;
      }

      li:before {
        margin-right: 0.1rem;
        margin-left: 0.1rem;
        content: "|";
      }

      li:first-child:before {
        content: "";
      }

      li.lang_link {
        margin-left: 0.3rem !important;
      }
    }
  }
}

.site-title {
  font-family: "Playfair Display", "Noto Naskh Arabic";
  font-size: 26px;
  font-weight: 300;
  line-height: 56px;
  margin-bottom: 0;
  display: inline-block;

  &, &:visited {
    color: $title-color;
  }

  @include media-query($on-palm) {
    font-size: 1.12rem;
    float: left;
  }
}


// ** Site footer
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0 ($spacing-unit / 2) 0;
}

.footer-wrapper {
  padding-left: $spacing-unit * 4;
  padding-right: $spacing-unit * 4;

  @include media-query($on-laptop) {
    padding-right: $spacing-unit / 1;
    padding-left: $spacing-unit / 1;
  }
}

.footer-left {
  float: left;

  @include media-query($on-laptop) {
    float: left;
  }

  @include media-query($on-palm) {
    text-align: center;
  }
}

.footer-right {
  float: right !important;

  @include media-query($on-laptop) {
    float: right;
    text-align: right;
  }

  @include media-query($on-palm) {
    text-align: center;
  }
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}


.page-custom-text {
  font-size: 1rem;
  color: #6B4B42;

  p {
    font-size: 1rem;
  }

  @include media-query($on-laptop) {
    font-size: 0.9rem;
    p {
      font-size: 0.9rem;
    }
  }
  @include media-query($on-palm) {
    font-size: 0.8rem;
    p {
      font-size: 0.8rem;
    }
  }
}

.items-list, .contact-list, .social-media-list, .works-list {
  list-style: none;
  margin-left: 0;
}

.items-list {
  @extend .page-custom-text;

  small.asterisk {
    font-size: 1.2rem;
    font-weight: bolder;
    display: inline-block;
    position: relative;
    top: -30%;
    transform: translateY(10%) !important;
    padding: 0.2em;
    color: #5d4037;
  }

  @include media-query($on-laptop) {
    small.asterisk {
      font-size: 1rem;
    }
  }

  @include media-query($on-palm) {
    small.asterisk {
      font-size: 0.9rem;
    }
  }
}

.about-list {
  li {
    margin: 0 0 -0.45rem 0;
    .detail {
      display: block;
      margin: 0;
      opacity: 95%;
      padding-left: 1.7rem;
      font-size: 0.9rem;
      @include media-query($on-palm) {
        padding-left: 1rem;
        font-size: inherit;
      }
    }
    .detail:last-child {
      margin-bottom: 0.75rem;
    }
    .detail {
      .tooltip {
        border-bottom: 1px dotted #8c8b8b;
      }
    }
    .thesis {
      display: block !important;
      margin-left: 0.4rem;
    }
  }
}

.social-links {
  text-align: center;

  a {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    opacity: 0.5;
    filter: alpha(opacity=50);
    text-decoration: none;
  }

  a:hover {
    opacity: 1.0;
    filter: alpha(opacity=100);
  }

  .icon {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    vertical-align: middle;
    opacity: 80%;
  }
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}


// ** Page content
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;
}

.post-meta {
  color: $brand-color;
}

.post-link {
  display: block;
  font-family: "Playfair Display", "Noto Naskh Arabic";
  font-size: 1.9rem;

  @include media-query($on-laptop) {
    font-size: 1.4rem;
  }

  @include media-query($on-palm) {
    font-size: 1.4rem;
  }
}


// ** Posts
.post-header {
  margin-bottom: $spacing-unit;
}

.page-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  color: $grey-color;
  font-family: "Playfair Display", "Noto Naskh Arabic";
  font-size: 1.9rem;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}


#post-nav {
  display: block;
  padding: 0 2em;

  .next, .previous {
    float: left;
    margin: 1rem 0;
    width: 50%;

    span:after, span:before {
      border: solid transparent 4px;
      content: "";
      display: inline-block;
      height: 0;
      margin: 0 0.5rem;
      top: 0.8em;
      width: 0;
    }
  }

  .next {
    span:before {
      border-left: 0;
      border-right-color: $grey-color;
    }
  }

  .previous {
    text-align: right;

    span:after {
      border-left-color: $grey-color;
      border-right: 0;
    }
  }

  @include media-query($on-palm) {
    padding: initial;

    .next, .previous {
      text-align: center;
      width: 100%;
    }
  }

  span {
    color: $brand-color;
    display: block;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  a {
    color: $grey-color;
    font-style: italic;
  }
}

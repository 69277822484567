@charset "utf-8";

// Font variables
$font-name: libretto-icons;
$font-path-file-name: /public/fonts/$font-name/$font-name;

$devicons-font-name: devicons;
$devicons-path-file-name: /public/fonts/devicons/$devicons-font-name;

@font-face {
  font-family: $font-name;
  src: url($font-path-file-name+'.eot'); /* IE9 Compat Modes */
  src: url($font-path-file-name+'.woff') format('woff'), /* Pretty Modern Browsers */
  url($font-path-file-name+'.ttf')  format('truetype'), /* Safari, Android, iOS */
  url($font-path-file-name+'.svg#font_name') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: $devicons-font-name;
  src: url($devicons-path-file-name+'.eot?xqxft6');
  src: url($devicons-path-file-name+'.eot?#iefixxqxft6') format('embedded-opentype'),
       url($devicons-path-file-name+'.woff?xqxft6') format('woff'),
       url($devicons-path-file-name+'.ttf?xqxft6') format('truetype'),
       url($devicons-path-file-name+'.svg?xqxft6#devicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Our variables
$base-font-family: "Libre Baskerville", Baskerville, "Book Antiqua", "Noto Naskh Arabic", Georgia, Times, serif;
$base-font-size:   16px;
$base-font-weight: 400;
$base-line-height: 2;
$small-font-size:  $base-font-size * 0.875;

$spacing-unit:     30px;

$brand-color:      #787065;
$background-color: #f2f1ed;
$text-color:       #363431;
$title-color:      #943526;
$highlight-color:  #943526;
$secondary-color:  #5D5A54;
$cheers-color:     #A28669;
$light-color:      #D8B28A;

$grey-color:       #a09a92;
$grey-color-light: lighten($grey-color, 30%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    1050px;

$on-palm:          600px;
$on-laptop:        800px;
$on-monitor:       1500px;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base", "layout", "syntax-highlighting", "custom", "devicons";

/**
 * Devicons 1.8.0 made by Theodore Vorillas / http://vorillaz.com
 */

/**
 * Fonts links and vars defined in main.scss.
 */

.devicons {
	font-family: 'devicons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 1.75rem;
	vertical-align: middle;
	color: $brand-color;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$devicons:	(git "\e602"),
			(git_compare "\e628"),
			(git_branch "\e625"),
			(git_commit "\e629"),
			(git_pull_request "\e626"),
			(git_merge "\e627"),
			(bitbucket "\e603"),
			(github_alt "\e608"),
			(github_badge "\e609"),
			(github "\e60a"),
			(github_full "\e617"),
			(java "\e638"),
			(ruby "\e639"),
			(scala "\e637"),
			(python "\e63c"),
			(go "\e624"),
			(ruby_on_rails "\e63b"),
			(django "\e61d"),
			(markdown "\e63e"),
			(php "\e63d"),
			(mysql "\e604"),
			(streamline "\e605"),
			(database "\e606"),
			(laravel "\e63f"),
			(javascript "\e64e"),
			(angular "\e653"),
			(backbone "\e652"),
			(coffeescript "\e651"),
			(jquery "\e650"),
			(modernizr "\e620"),
			(jquery_ui "\e654"),
			(ember "\e61b"),
			(dojo "\e61c"),
			(nodejs "\e619"),
			(nodejs_small "\e618"),
			(javascript_shield "\e64f"),
			(bootstrap "\e647"),
			(sass "\e64b"),
			(css3_full "\e64a"),
			(css3 "\e649"),
			(html5 "\e636"),
			(html5_multimedia "\e632"),
			(html5_device_access "\e633"),
			(html5_3d_effects "\e635"),
			(html5_connectivity "\e634"),
			(ghost_small "\e614"),
			(ghost "\e61f"),
			(magento "\e640"),
			(joomla "\e641"),
			(jekyll_small "\e60d"),
			(drupal "\e642"),
			(wordpress "\e60b"),
			(grunt "\e64c"),
			(bower "\e64d"),
			(npm "\e61e"),
			(yahoo_small "\e62b"),
			(yahoo "\e615"),
			(bing_small "\e600"),
			(windows "\e60f"),
			(linux "\e612"),
			(ubuntu "\e63a"),
			(android "\e60e"),
			(apple "\e611"),
			(appstore "\e613"),
			(phonegap "\e630"),
			(blackberry "\e623"),
			(stackoverflow "\e610"),
			(techcrunch "\e62c"),
			(codrops "\e62f"),
			(css_tricks "\e601"),
			(smashing_magazine "\e62d"),
			(netmagazine "\e62e"),
			(codepen "\e616"),
			(cssdeck "\e62a"),
			(hackernews "\e61a"),
			(dropbox "\e607"),
			(google_drive "\e631"),
			(visualstudio "\e60c"),
			(unity_small "\e621"),
			(raspberry_pi "\e622"),
			(chrome "\e643"),
			(ie "\e644"),
			(firefox "\e645"),
			(opera "\e646"),
			(safari "\e648"),
			(swift "\e655"),
			(symfony "\e656"),
			(symfony_badge "\e657"),
			(less "\e658"),
			(stylus "\e659"),
			(trello "\e65a"),
			(atlassian "\e65b"),
			(jira "\e65c"),
			(envato "\e65d"),
			(snap_svg "\e65e"),
			(raphael "\e65f"),
			(google_analytics "\e660"),
			(compass "\e661"),
			(onedrive "\e662"),
			(gulp "\e663"),
			(atom  "\e664"),
			(cisco "\e665"),
			(nancy "\e666"),
			(clojure "\e668"),
			(clojure_alt "\e66a"),
			(perl "\e669"),
			(celluloid "\e66b"),
			(w3c "\e66c"),
			(redis "\e66d"),
			(postgresql "\e66e"),
			(webplatform "\e66f"),
			(jenkins "\e667"),
			(requirejs "\e670"),
			(opensource "\e671"),
			(typo3 "\e672"),
			(uikit "\e673"),
			(doctrine "\e674"),
			(groovy "\e675"),
			(nginx "\e676"),
			(haskell "\e677"),
			(zend "\e678"),
			(gnu "\e679"),
			(yeoman "\e67a"),
			(heroku "\e67b"),
			/* new in 1.5.0 */
			(debian "\e67d"),
			(travis "\e67e"),
			(dotnet "\e67f"),
			(codeigniter "\e680"),
			(javascript_badge "\e681"),
			(yii "\e682"),
			(msql_server "\e67c"),
			(composer "\e683"),
			(krakenjs_badge "\e684"),
			(krakenjs "\e685"),
			(mozilla "\e686"),
			(firebase "\e687"),
			(sizzlejs "\e688"),
			(creativecommons "\e689"),
			(creativecommons_badge "\e68a"),
			(mitlicence "\e68b"),
			(senchatouch "\e68c"),
			(bugsense "\e68d"),
			(extjs "\e68e"),
			(mootools_badge "\e68f"),
			(mootools "\e690"),
			(ruby_rough "\e691"),
			(komodo "\e692"),
			(coda "\e693"),
			(bintray "\e694"),
			(terminal "\e695"),
			(code "\e696"),
			(responsive "\e697"),
			(dart "\e698"),
			(aptana "\e699"),
			(mailchimp "\e69a"),
			(netbeans "\e69b"),
			(dreamweaver "\e69c"),
			(brackets "\e69d"),
			(eclipse "\e69e"),
			(cloud9 "\e69f"),
			(scrum "\e6a0"),
			(prolog "\e6a1"),
			(terminal_badge "\e6a2"),
			(code_badge "\e6a3"),
			(mongodb "\e6a4"),
			(meteor "\e6a5"),
			(meteorfull "\e6a6"),
			(fsharp "\e6a7"),
			(rust "\e6a8"),
			(ionic "\e6a9"),
			(sublime "\e6aa"),
			(appcelerator "\e6ab"),
			(asterisk "\e6ac"),
			(aws "\e6ad"),
			(digital-ocean "\e6ae"),
			(dlang "\e6af"),
			(docker "\e6b0"),
			(erlang "\e6b1"),
			(google-cloud-platform "\e6b2"),
			(grails "\e6b3"),
			(illustrator "\e6b4"),
			(intellij "\e6b5"),
			(materializecss "\e6b6"),
			(openshift "\e6b7"),
			(photoshop "\e6b8"),
			(rackspace "\e6b9"),
			(react "\e6ba"),
			(redhat "\e6bb"),
			(scriptcs "\e6bc"),
			(sqllite "\e6c4"),
			(vim "\e6c5");

@each $devicon in $devicons {
	  $iconClass: nth($devicon, 1);
	  $iconClassBefore: nth($devicon, 2);
	  .devicons-#{$iconClass}{
	  	&:before{
	  		content: "#{$iconClassBefore}";
	  	}
	  }
}

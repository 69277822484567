// ** Reset some basic elements
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}


// ** Basic styling
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}


// ** Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure, %vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

.center { text-align: center; }
.left   { text-align: left;   }
.right  { text-align: right;  }

// ** Articles
article {
  background: #faf9f5;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: $spacing-unit;
  padding: 1.5em 2em 1.2em;

  @include media-query($on-palm) {
    padding: 1em 1em 0.3em;
  }

  a {
    border-bottom: 0.03rem solid  $brand-color;
  }

  > .entry-content {
    @include media-query($on-palm) {
      font-size: 80%;
    }

    // &:first-letter {
    //   color: #b7b1a9;
    //   display: inline-block;
    //   float: left;
    //   font-family: "Playfair Display", "Noto Naskh Arabic";
    //   font-size: 121px;
    //   font-style: normal;
    //   font-weight: 700;
    //   line-height: 70px;
    //   margin: 6px 10px 0 -5px;

    //   @include media-query($on-palm) {
    //     font-size: 70px;
    //     line-height: 30px;
    //   }
    // }

    // &:first-line {
    //   color: #787065;
    //   font-family: "Playfair Display SC", "Noto Naskh Arabic";
    //   font-size: 1em;
    //   font-style: normal !important;
    //   font-weight: bold;
    //   letter-spacing: 2px;
    // }
  }
}

.post-header {
  border-bottom: 1px solid #d9d6d0;
  margin-bottom: 2rem;
  text-align: center;

  a {
    border-bottom: none !important;
  }

  &:before {
    background: #faf9f5;
    border: 1px solid #d9d6d0;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #faf9f5;
    color: #a09a92;
    content: "p";
    display: inline-block;
    font-family: libretto-icons;
    font-size: 20px;
    line-height: 21px;
    margin: 0.4rem auto 0.8rem;
    padding: .6rem;
    text-shadow: none;
  }

  > div {
    color: $grey-color;
    font-size: $base-font-size - 2;
    font-style: italic;

    @include media-query($on-palm) {
      font-size: $small-font-size - 1;
    }

    &:before {
      color: #d9d6d0;
      content: "~";
      display: inline-block;
      font-family: libretto-icons;
      font-size: 20px;
      margin: 0 0.25rem;
      text-shadow: none;
      vertical-align: -6px;
    }

    &:after {
      color: #d9d6d0;
      content: "`";
      display: inline-block;
      font-family: libretto-icons;
      font-size: 20px;
      margin: 0 0.25rem;
      text-shadow: none;
      vertical-align: -6px;
    }
  }
}

.post-list {
  .post-header {
    &:before {
      background: #eae9e6;
      border-color: #faf9f5;
      box-shadow: 0 0 0 4px #eae9e6;
    }
  }
}

.page-header {
  @extend .post-header;
  &:before {
    content: "";
  }
}

.page-header-about {
  &:before {
    content: "q";
  }
}

.page-header-works {
  &:before {
    content: "a";
  }
}

.page-header-contact {
  &:before {
    content: "c";
  }
}

// ** Overrides h* styling
.entry-content {
  h1, h2, h3, h4 {
    margin-top: $spacing-unit / 1;
    margin-bottom: $spacing-unit / 1.5;
  }
}

// ** Images
img {
  max-width: 100%;
  vertical-align: middle;
}


// ** Figures
figure {
  // background: #fff;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.025);
  padding: 0.5em;
}

figure > img {
  display: block;
}

figcaption {
  color: $grey-color;
  font-size: 0.9rem;
  font-style: italic;
  line-height: 1.2;
  margin: 1em 0.3em 0;
  text-align: center;

  @include media-query($on-palm) {
    font-size: 0.75rem;
  }
}


// ** Lists
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
   margin-bottom: 0;
 }
}


// ** Headings
h1, h2, h3, h4, h5, h6 {
  line-height: initial;
  font-weight: $base-font-weight;
}


// ** Links
a {
  color: $brand-color;
  text-decoration: none;

  &:hover {
    color: darken($brand-color, 5%);
    text-decoration: none;
  }
}


// ** Blockquotes
blockquote {
  color: $grey-color;
  border-left: .25rem solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  font-size: .9rem;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}


// ** Code formatting
pre, code {
  font-size: 15px;
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
  // word-break: keep-all;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

// ** Wrapper
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}

.wrapper-fluid {
  @extend %clearfix;
  padding-right: $spacing-unit / 1;
  padding-left: $spacing-unit / 1;
}

// ** Clearfix
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

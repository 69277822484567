.highlight {
  @extend %vertical-rhythm;
  background: #fff;

  .hll { background-color: #ffc; }
  .c  { color: #999; } /* Comment */
  .err { color: #a00; background-color: #faa } /* Error */
  .k  { color: #069; } /* Keyword */
  .o  { color: #555 } /* Operator */
  .cm { color: #09f; font-style: italic } /* Comment.Multiline */
  .cp { color: #099 } /* Comment.Preproc */
  .c1 { color: #999; } /* Comment.Single */
  .cs { color: #999; } /* Comment.Special */
  .gd { background-color: #fcc; border: 1px solid #c00 } /* Generic.Deleted */
  .ge { font-style: italic } /* Generic.Emph */
  .gr { color: #f00 } /* Generic.Error */
  .gh { color: #030; } /* Generic.Heading */
  .gi { background-color: #cfc; border: 1px solid #0c0 } /* Generic.Inserted */
  .go { color: #aaa } /* Generic.Output */
  .gp { color: #009; } /* Generic.Prompt */
  .gs { } /* Generic.Strong */
  .gu { color: #030; } /* Generic.Subheading */
  .gt { color: #9c6 } /* Generic.Traceback */
  .kc { color: #069; } /* Keyword.Constant */
  .kd { color: #069; } /* Keyword.Declaration */
  .kn { color: #069; } /* Keyword.Namespace */
  .kp { color: #069 } /* Keyword.Pseudo */
  .kr { color: #069; } /* Keyword.Reserved */
  .kt { color: #078; } /* Keyword.Type */
  .m  { color: #f60 } /* Literal.Number */
  .s  { color: #d44950 } /* Literal.String */
  .na { color: #4f9fcf } /* Name.Attribute */
  .nb { color: #366 } /* Name.Builtin */
  .nc { color: #0a8; } /* Name.Class */
  .no { color: #360 } /* Name.Constant */
  .nd { color: #99f } /* Name.Decorator */
  .ni { color: #999; } /* Name.Entity */
  .ne { color: #c00; } /* Name.Exception */
  .nf { color: #c0f } /* Name.Function */
  .nl { color: #99f } /* Name.Label */
  .nn { color: #0cf; } /* Name.Namespace */
  .nt { color: #2f6f9f; } /* Name.Tag */
  .nv { color: #033 } /* Name.Variable */
  .ow { color: #000; } /* Operator.Word */
  .w  { color: #bbb } /* Text.Whitespace */
  .mf { color: #f60 } /* Literal.Number.Float */
  .mh { color: #f60 } /* Literal.Number.Hex */
  .mi { color: #f60 } /* Literal.Number.Integer */
  .mo { color: #f60 } /* Literal.Number.Oct */
  .sb { color: #c30 } /* Literal.String.Backtick */
  .sc { color: #c30 } /* Literal.String.Char */
  .sd { color: #c30; font-style: italic } /* Literal.String.Doc */
  .s2 { color: #c30 } /* Literal.String.Double */
  .se { color: #c30; } /* Literal.String.Escape */
  .sh { color: #c30 } /* Literal.String.Heredoc */
  .si { color: #a00 } /* Literal.String.Interpol */
  .sx { color: #c30 } /* Literal.String.Other */
  .sr { color: #3aa } /* Literal.String.Regex */
  .s1 { color: #c30 } /* Literal.String.Single */
  .ss { color: #fc3 } /* Literal.String.Symbol */
  .bp { color: #366 } /* Name.Builtin.Pseudo */
  .vc { color: #033 } /* Name.Variable.Class */
  .vg { color: #033 } /* Name.Variable.Global */
  .vi { color: #033 } /* Name.Variable.Instance */
  .il { color: #f60 } /* Literal.Number.Integer.Long */
}
